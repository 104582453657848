import styled from "styled-components";
import { Paragraph, ParagraphWithLightBorder } from "./About";
import { Button, Input, InputContainer, TextArea } from "./CallBack";
import { WrapperContainer } from "./SliderItem";
import { smDown } from "../utils/responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ChangeEvent, useLayoutEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import emailjs from "@emailjs/browser";
import { Comment } from 'react-loader-spinner';
import { commonData } from "../data/common";

const Container = styled(WrapperContainer)`
  padding: 48px 12px;
`;
const Wrapper = styled.div`
  margin: 0 -12px;
  display: flex;
  flex-wrap: wrap;
`;
const Col = styled.div`
  padding: 0 12px;
  width: 50%;
  ${smDown({
  width: "100%",
})}
`;
const StyledParagraph = styled(Paragraph)``;
const ContactRowContainer = styled.div`
  padding: 8px 0 8px 0;
`;
const ContactRow = styled(Paragraph)`
  color: ${({ theme }) => theme.palette.primary.darker};
  margin-top: 8px;
  margin-bottom: 8px;
`;
const ContactRowIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.primary.darker};
  margin-right: 16px;
`;
const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`;
const StyledInputContainer = styled(InputContainer)`
  width: 100%;
`;
const FormButton = styled(Button)`
  margin: 16px 8px;
`;
const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  min-height: 450px;
  border: 0;
  border-radius: ${({ theme }) => theme.borderRadius};
`;
const Contact = () => {
  const [nameVal, setNameVal] = useState({ value: '' });
  const [emailVal, setEmailVal] = useState({ value: '' });
  const [subjectVal, setSubjectVal] = useState({ value: '' });
  const [messageVal, setMessageVal] = useState({ value: '' });
  const [sendLoad, setSendLoad] = useState(false);
  const handleChangeName = (event: ChangeEvent<{ value: string }>) => {
    setNameVal({ value: event?.currentTarget?.value });
  }
  const handleChangeEmail = (event: ChangeEvent<{ value: string }>) => {
    setEmailVal({ value: event?.currentTarget?.value });
  }
  const handleChangeSubject = (event: ChangeEvent<{ value: string }>) => {
    setSubjectVal({ value: event?.currentTarget?.value });
  }
  const handleChangeMessage = (event: ChangeEvent<{ value: string }>) => {
    setMessageVal({ value: event?.currentTarget?.value });
  }
  const onSubmit = async (event: any) => {
    event.preventDefault()
    if (!nameVal.value || !emailVal.value) {
      alert("Please atleast provide name and email");
      return;
    }
    setSendLoad(true)
    try {
      const templateParams = {
        name: nameVal.value,
        email: emailVal.value,
        mobile: "",
        subject: subjectVal.value,
        message: messageVal.value,
      };
      await emailjs.send(
        "service_l7dvock",
        "template_fjhrvxe",
        templateParams,
        "RF-Lcbk9v1sON25zz"
      );
      console.log("success")
      setSendLoad(false)
      reset();
    } catch (e) {
      setSendLoad(false)
      console.log(e);
    }
  };

  const reset = () => {
    setNameVal({ value: "" });
    setEmailVal({ value: "" });
    setSubjectVal({ value: "" });
    setMessageVal({ value: "" });
  };
  // Container scroll trigger animation
  const containerEl = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const contactTween = gsap.from(containerEl.current, {
      opacity: 0,
      scrollTrigger: {
        trigger: containerEl.current,
      },
    });

    return () => {
      if (contactTween) contactTween.kill();
    };
  }, []);
  return (
    <Container ref={containerEl}>
      <Wrapper>
        <Col>
          <ParagraphWithLightBorder>Contact us</ParagraphWithLightBorder>
          <StyledParagraph>
            Financial matters are crucial and complexed, but if you are a partner with Influx Capital you experience relaxation and professionalism. We work with integrity maintaining complete transparency.If you are looking for financial solution companies that can give you peace of mind, then you know whom to call.
          </StyledParagraph>
          <ContactRowContainer>
            <ContactRow>
              <a href={"tel:" + commonData.phone}><ContactRowIcon icon={["fas", "phone"]} ></ContactRowIcon>{commonData.phone}</a>
            </ContactRow>
            <ContactRow>
              <a href={"mailto:" + commonData.email}><ContactRowIcon icon={["fas", "envelope"]} ></ContactRowIcon>{commonData.email}</a>
            </ContactRow>
          </ContactRowContainer>
          <Form>
            <InputContainer>
              <Input placeholder="Your Name" value={nameVal.value} onChange={handleChangeName} required/>
            </InputContainer>
            <InputContainer>
              <Input placeholder="Your Email" value={emailVal.value} onChange={handleChangeEmail} required/>
            </InputContainer>
            <StyledInputContainer>
              <Input placeholder="Subject" value={subjectVal.value} onChange={handleChangeSubject} />
            </StyledInputContainer>
            <TextArea placeholder="Message" value={messageVal.value} onChange={handleChangeMessage} />
            <FormButton type="submit" onClick={onSubmit} disabled={sendLoad}>Send Message</FormButton>
            {sendLoad && <Comment
              visible={true}
              height="80"
              width="80"
              ariaLabel="comment-loading"
              wrapperStyle={{}}
              wrapperClass="comment-wrapper"
              color="#fff"
              backgroundColor="#F4442E"
            />}
          </Form>
        </Col>
        <Col>
          <Iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.465673107025!2d77.5052839746712!3d12.813157118332992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae410fcac40e7d%3A0x377a2a3d09ef4473!2sBrigade%20meadows!5e0!3m2!1sen!2sin!4v1718082027556!5m2!1sen!2sin"
            aria-hidden="false"
            tabIndex={0}
          />
        </Col>
      </Wrapper>
    </Container>
  );
};

export default Contact;
