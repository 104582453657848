import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCircleXmark,
  faPhoneFlip,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gsap } from "gsap";
import { ParagraphWithLightBorder } from "./About";
import React, { useLayoutEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { lgDown, mdDown, smDown, xlDown, xsDown } from "../utils/responsive";
import {
  ButtonLink,
  WrapperContainer,
  imgbaseUrl,
} from "./SliderItem";
import {
  Title,
} from "./About";
library.add(faCircleXmark, faUsers, faPhoneFlip);
const Container = styled(WrapperContainer)`
  padding: 48px 12px;
`;
const ColWrapper = styled.div`
  display: flex;
  margin: 0 -12px 24px;
  ${mdDown({
  flexDirection: "column",
})};
`;
const Col = styled.div`
  padding: 0 12px;
  height: 500px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  ${xlDown({
  height: 300,
})};
  ${lgDown({
  height: 586,
})};
  ${mdDown({
  width: "100%",
})};
`;
const Left = styled(Col)`
  opacity: 0;
  transform: translateY(100%);
  ${mdDown({
  height: 696,
})};
  ${smDown({
  height: 516,
})};
  ${xsDown({
  height: "auto",
})};
`;
const Right = styled(Col)`
  height: auto;
  opacity: 0;
  transform: translateY(100%);
`;
const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: ${({ theme }) => theme.borderRadius};
`;
export const Desc = styled.p`
  color: ${({ theme }) => theme.palette.common.black};
  margin-bottom: 24px;
  font-weight: 400;
`;
export const fadeout = keyframes`
  0% {
    display:none;
    opacity:0;
  }
  70% {
    display:none;
    opacity:0;
  }
  100% {
    display:block;
    opacity:1;
  }
`;
export const Paragraph = styled.p`
  color: ${({ theme }) => theme.palette.common.black};
`;
const AboutFooter = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.primary.light};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  ${mdDown({
  flexDirection: "column",
})}
`;
const AboutFooterItem = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 10px;
  display: flex;
  border: 1px solid transparent;
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 3rem;
`;
const AboutFooterDescContainer = styled.div`
  padding-left: 10px;
  ${smDown({
  paddingLeft: 12,
})}
`;
export const TitleH4 = styled.h4`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 1rem;
  margin-bottom: 8px;
  ${lgDown({
  fontSize: "calc(1.275rem + .3vw)",
})}
`;
const AboutFooterItemParagraph = styled.p`
  color: ${({ theme }) => theme.palette.common.black};
  ${mdDown({
  marginBottom: 10,
})}
`;
const ContactButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;
interface serviceDetailProps {
  service: any;
}
const ServiceDetail = ({ service }: serviceDetailProps) => {
  // Left on scroll animation
  const leftEl = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const tween = gsap.to(leftEl.current, {
      opacity: 1,
      y: 0,
      delay: 0.1,
      duration: 0.1,
      scrollTrigger: {
        trigger: leftEl.current,
        start: "top-=170% center",
      },
    });
    return () => {
      tween.scrollTrigger?.kill();
    };
  }, []);
  // Right on scroll animation
  const rightEl = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const tween = gsap.to(rightEl.current, {
      opacity: 1,
      y: 0,
      delay: 0.2,
      duration: 0.2,
      scrollTrigger: {
        trigger: rightEl.current,
        start: "top-=150% center",
      },
    });
    return () => {
      tween.scrollTrigger?.kill();
    };
  }, []);
  // aboutFooter container and child animation on scroll
  const aboutFooterEl = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const t1 = gsap.timeline({
      scrollTrigger: {
        trigger: aboutFooterEl.current,
        start: "top-=400% center",
      },
    });
    t1.from(aboutFooterEl.current, {
      opacity: 0,
      y: "100%",
      delay: 0.1,
      duration: 0.2,
    });
    t1.from(revealRefs.current, {
      opacity: 0,
      y: "100%",
      duration: 0.5,
      stagger: 0.1,
    });
    return () => t1.scrollTrigger?.kill();
  }, []);
  // AboutFooterItem animation on scroll
  const revealRefs = useRef<HTMLDivElement[]>([]);
  const addToRefs = (el: HTMLDivElement) => {
    if (el && !revealRefs.current.includes(el)) revealRefs.current.push(el);
  };
  return (
    <Container>
      <ColWrapper>
        <Left ref={leftEl}>
          <Image src={`${imgbaseUrl}${service.img}.jpg`} />
        </Left>
        <Right ref={rightEl}>
          <ParagraphWithLightBorder>{service.name}</ParagraphWithLightBorder>
          <Desc>
            {service.descOne}
          </Desc>
          {service.descTwo && <Desc>
            {service.descTwo}
          </Desc>}
        </Right>
      </ColWrapper>
      {service.subName && <Title>
        {service.subName}
      </Title>}
      {service.subList && <AboutFooter ref={aboutFooterEl}>
        {service.subList.map(({ name, desc }: any, idx: React.Key | null | undefined) => (
          <AboutFooterItem ref={addToRefs} key={idx}>
            <AboutFooterDescContainer>
              <TitleH4>{name}</TitleH4>
              <AboutFooterItemParagraph>{desc}</AboutFooterItemParagraph>
            </AboutFooterDescContainer>
          </AboutFooterItem>
        ))}
      </AboutFooter>}
      <ContactButtonContainer>
        <ButtonLink to="/contact">Contact Us</ButtonLink>
      </ContactButtonContainer>
    </Container>
  );
};

export default ServiceDetail;
