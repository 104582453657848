import React from "react";
import { Helmet } from "react-helmet-async";
import PageHeader from "../components/PageHeader";
import ServiceDetail from "../components/ServiceDetail";
import { realEstate } from "../data/serviceDetail";

const RealEstate = () => {
  return (
    <>
      <Helmet>
        <title>Influx Capital | Services - Real Estate</title>
        <meta name="description" content="Creating an engaging and persuasive real estate promotion description involves highlighting the unique aspects of the property and its surroundings, appealing to potential buyers' desires and needs" />
        <link rel="canonical" href="/realestate" />
      </Helmet>
      <PageHeader title="Real Estate" subPage="Services"/>
      <ServiceDetail service={realEstate}/>
    </>
  );
};

export default RealEstate;
