import { library } from "@fortawesome/fontawesome-svg-core";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gsap } from "gsap";
import React, { useLayoutEffect, useRef } from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from "styled-components";
import { ParagraphWithLightBorder } from "./About";
import { WrapperContainer, imgbaseUrl } from "./SliderItem";
import { ScrollTrigger } from "gsap/ScrollTrigger";
library.add(faLink);

const Container = styled(WrapperContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 48px;
`;
const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const CardContainer = styled(Slider)`
  width: 100%;
  .slick-slide {
    box-sizing: border-box;
    padding: 25px;
  }
`;
const Card = styled.div`
  margin-top: 30px;
  position: relative;
  height: 230px;
  display: flex;
`;
const CardImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

const SlideBtnContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;
const SlideBtn = styled.div`
  height: 45px;
  width: 45px;
  margin: 0 12px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.primary.light};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 500ms;
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    & svg {
      color: ${({ theme }) => theme.palette.common.white};
    }
  }
`;
const SlideBtnIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
`;
const Partners = () => {
  const settings: Settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          centerMode: true,
          slidesToShow: 2,
          centerPadding: "170px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
          slidesToShow: 2,
          centerPadding: "0px",
        },
      },
    ],
  };
  const slideEl = useRef<Slider | null>(null);
  const handleSlide = (direction: string) => {
    if (direction === "left") slideEl.current?.slickPrev();
    else slideEl.current?.slickNext();
  };
  // Top and slider scroll trigger animation
  const containerEl = useRef<HTMLDivElement>(null);
  const topEl = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: containerEl.current,
        start: "top center",
      },
    });
    tl.from(topEl.current, {
      opacity: 0,
      y: "100%",
    }).from(".project-slick-list", {
      opacity: 0,
      y: "100%",
    });
    return () => {
      if (tl.scrollTrigger) tl.scrollTrigger.kill();
    };
  }, []);
  const handleLoad = () => {
    ScrollTrigger.refresh();
  };
  return (
    <Container ref={containerEl}>
      <Top ref={topEl}>
        <ParagraphWithLightBorder>Our Partners</ParagraphWithLightBorder>
      </Top>
      <CardContainer className="project-slick-list" ref={slideEl} {...settings}>
        {[1, 2, 3, 4, 5, 6, 7, 8].map((idx) => (
          <Card key={idx}>
            <CardImage
              onLoad={handleLoad}
              src={`${imgbaseUrl}partner-${idx}.png`}
            />
          </Card>
        ))}
      </CardContainer>
      <SlideBtnContainer>
        <SlideBtn onClick={() => handleSlide("left")}>
          <SlideBtnIcon icon={["fas", "chevron-left"]} />
        </SlideBtn>
        <SlideBtn onClick={() => handleSlide("right")}>
          <SlideBtnIcon icon={["fas", "chevron-right"]} />
        </SlideBtn>
      </SlideBtnContainer>
    </Container>
  );
};

export default Partners;
