import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { ParagraphWithLightBorder, Title, fadeout } from "./About";
import { ButtonLink, WrapperContainer, imgbaseUrl } from "./SliderItem";
import { mdDown, smDown } from "../utils/responsive";
import { gsap } from "gsap";

const Container = styled(WrapperContainer)`
  padding: 48px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const TitleWithBigMargin = styled(Title)`
  margin-bottom: 48px;
  max-width: 600px;
  text-align: center;
`;
const Bottom = styled.div`
  display: flex;
  width: 100%;
  ${mdDown({
  flexDirection: "column",
})}
`;
const Col = styled.div`
  flex: 1;
`;
const NavPillsContainer = styled(Col)`
  padding-right: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${mdDown({
  paddingRight: 0,
})}
`;
interface NavPillProps {
  isSelected: boolean;
}
const NavPill = styled.button<NavPillProps>`
  width: 100%;
  padding: 24px;
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.palette.primary.main : "transparent"};
  border: 1px solid ${({ theme }) => theme.palette.primary.light};
  border-radius: ${({ theme }) => theme.borderRadius};
  cursor: pointer;
  transition: all 100ms ease-in-out;
  & h5 {
    color: ${({ theme, isSelected }) =>
    isSelected ? theme.palette.common.white : "initial"};
  }
  & svg {
    color: ${({ theme, isSelected }) =>
    isSelected ? theme.palette.common.white : theme.palette.primary.main};
  }
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;
const NavPillText = styled.h5`
  font-size: 1.25rem;
  text-align: left;
  transition: all 100ms ease-in-out;
`;
const NavPillIcon = styled(FontAwesomeIcon)`
  margin-right: 16px;
  transition: all 100ms ease-in-out;
`;
interface RightProps {
  isVisible?: boolean;
}
const Right = styled.div<RightProps>`
  flex: 2;
  padding-left: 12px;
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  animation: ${fadeout} 300ms linear;
  ${mdDown({
  paddingLeft: 0,
  marginTop: 24,
})}
  ${smDown({
  flexDirection: "column",
})}
`;

const ImageContainer = styled(Col)`
  padding-right: 12px;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius};
  ${smDown({
  paddingRight: 0,
  flex: 0,
  minHeight: 350,
})}
  position:relative;
`;
const Image = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.borderRadius};
`;

const RightDescContainer = styled(Col)`
  padding-left: 12px;
  padding-right: 12px;
  ${smDown({
  paddingLeft: 0,
  paddingRight: 0,
  marginTop: 24,
})}
`;
const RightDesc = styled.h1`
  margin-bottom: 24px;
`;
const RightButton = styled(ButtonLink)`
  margin-top: 16px;
`;
const Services = () => {
  const [pillIndex, setPillIndex] = useState(0);
  const handleChangePillIndex = (idx: number) => {
    setPillIndex(idx);
  };

  interface IRight {
    title: string;
    desc: string;
    to: string;
  }
  const rightItems: IRight[] = [
    {
      title: "Retail Loans",
      desc: "Deals that are pocket friendly to buy a Home or to manage personal expenses.",
      to: "/retailloan",
    },
    {
      title: "SME Loans",
      desc: "Loans that are tailored to suit the requirements of your SME business.",
      to: "/smeloan",
    },
    {
      title: "Real Estate",
      desc: "Buy and purchase the properties at the best price with funding.",
      to: "/realestate",
    },
    {
      title: "Project finance",
      desc: "Get your dream project financed with the most reliable sources.",
      to: "/projectfinance",
    },
    {
      title: "Mergers & Acquisitions",
      desc: "Expert consultancy to acquire any new businesses at ease.",
      to: "/mergers",
    },
  ];
  const memoizedRights = useMemo(
    () =>
      rightItems.map(({ title, desc, to }, idx) => {
        return (
          <Right key={idx} isVisible={pillIndex === idx}>
            <ImageContainer>
              <Image src={`${imgbaseUrl}service-${pillIndex + 1}.jpg`} />
            </ImageContainer>
            <RightDescContainer>
              <RightDesc>{desc}</RightDesc>
              <RightButton to={to}>Read More</RightButton>
            </RightDescContainer>
          </Right>
        );
      }),
    [pillIndex]
  );
  // Scroll Trigger animation
  const containerEl = useRef<HTMLDivElement>(null);
  const topEl = useRef<HTMLDivElement>(null);
  const bottomEl = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const t1 = gsap.timeline({
      scrollTrigger: {
        trigger: containerEl.current,
        start: "top-=30% center",
      },
    });
    t1.from(topEl.current, {
      opacity: 0,
      y: "100%",
    }).from(bottomEl.current, {
      opacity: 0,
      y: "100%",
    });
    return () => {
      t1.scrollTrigger?.kill();
    };
  }, []);
  return (
    <Container ref={containerEl}>
      <Top ref={topEl}>
        <ParagraphWithLightBorder>Our Services</ParagraphWithLightBorder>
        <TitleWithBigMargin>
          How We Help Your Business?
        </TitleWithBigMargin>
      </Top>
      <Bottom ref={bottomEl}>
        <NavPillsContainer>
          {rightItems.map(({title, desc, to}, idx) => {
            return (
              <NavPill
                key={idx}
                isSelected={pillIndex === idx}
                onClick={() => handleChangePillIndex(idx)}
              >
                <NavPillText>
                  <NavPillIcon icon={["fas", "bars"]} />
                  {title}
                </NavPillText>
              </NavPill>
            );
          })}
        </NavPillsContainer>
        {memoizedRights}
      </Bottom>
    </Container>
  );
};

export default Services;
