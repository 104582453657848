import React from "react";
import { Helmet } from "react-helmet-async";
import PageHeader from "../components/PageHeader";
import ServiceDetail from "../components/ServiceDetail";
import { smeLoan } from "../data/serviceDetail";

const SmeLoan = () => {
  return (
    <>
      <Helmet>
        <title>Influx Capital | Services - SME Loans</title>
        <meta name="description" content="We understand the unique challenges and opportunities faced by small and medium-sized enterprises. Our SME loans are designed to provide you with the financial support you need to grow, innovate, and succeed" />
        <link rel="canonical" href="/smeloan" />
      </Helmet>
      <PageHeader title="SME Loans" subPage="Services"/>
      <ServiceDetail service={smeLoan}/>
    </>
  );
};

export default SmeLoan;
