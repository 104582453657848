import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import Service from "../pages/Service";
import RetailLoan from "../pages/RetailLoan";
import SmeLoan from "../pages/SmeLoan";
import RealEstate from "../pages/RealEstate";
import ProjectFinance from "../pages/ProjectFinance";
import Mergers from "../pages/Mergers";
import Testimonial from "../pages/Testimonial";
import Copyright from "./Copyright";
import Footer from "./Footer";
import Header from "./Header";

const RoutesWrapper = () => {
  const location = useLocation();
  const [prePath , setPrePath] = useState<string>()
  useEffect(() => {
    if(prePath !== location.pathname) {
      window.scrollTo(0, 0)
    }
    setPrePath(location.pathname)
  }, [location]);
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/retailloan" element={<RetailLoan />} />
        <Route path="/smeloan" element={<SmeLoan />} />
        <Route path="/realestate" element={<RealEstate />} />
        <Route path="/projectfinance" element={<ProjectFinance />} />
        <Route path="/mergers" element={<Mergers />} />
        <Route path="/testimonial" element={<Testimonial />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      <Copyright />
    </>
  );
};

export default RoutesWrapper;
