import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import { WrapperContainer, imgbaseUrl } from "./SliderItem";
import { Paragraph, ParagraphWithLightBorder, TitleH4 } from "./About";
import { TitleWithBigMargin } from "./Services";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { gsap } from "gsap";
library.add(faQuoteRight);
const Container = styled(WrapperContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 48px;
`;
const ContainerTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledSlider = styled(Slider)`
  margin: 0 -12px;
  width: 100%;
  .slick-dots {
    position: initial;
  }
  //Active Dots Style
  .slick-dots li.slick-active button {
    border-color: ${({ theme }) => theme.palette.primary.main};
    ::before {
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
  }
  .slick-dots li {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 0 5px;
  }
  .slick-dots li button {
    border: 1px solid ${({ theme }) => theme.palette.primary.light};
    height: 100%;
    width: 100%;
    border-radius: 50%;
    position: relative;
    ::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.palette.primary.light};
    }
  }
`;
const SlideItemContainer = styled.div`
  padding: 20px;
`;
const SlideItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Top = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.primary.light};
  padding: 48px 24px 24px;
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius};
  margin-bottom: 48px;
  ::after,
  ::before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    left: 50%;
    transform: translateX(-50%);
  }
  :after {
    top: 100%;
    border-color: ${({ theme }) => theme.palette.common.white} transparent
      transparent transparent;
    border-width: 30px;
  }
  :before {
    top: 100.11%;
    border-color: ${({ theme }) => theme.palette.primary.light} transparent
      transparent transparent;
    border-width: 32px;
  }
`;
const IconContainer = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.palette.primary.light};
  background-color: ${({ theme }) => theme.palette.common.white};
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
`;
const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 2rem;
`;
const StyledParagraph = styled(Paragraph)`
  text-align: center;
`;
const StyledTitleH4 = styled(TitleH4)`
  text-align: center;
`;
const Image = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 16px;
  border-radius: 50%;
`;
const Testimonial = () => {
  const settings: Settings = {
    dots: true,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 3,
    arrows: false,
    autoplay: true,
    speed: 800,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    responsive: [
      {
        breakpoint: 993,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "175px",
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };
  interface SlideItemType {
    description: string;
    img: string;
    name: string;
    profession: string;
  }
  const slideItemArray: SlideItemType[] = [
    {
      description:
        "I have known Jayakumar from 2010. His positive attitude and client (I was one of them) focussed approach was of immense help in closing my mortgage on time. Of course he also ensured good rates. Since then I referred him to three of my friends too, were very happy with his service. I wish him well in his future endeavours.",
      img: "0",
      name: "Haridas Menon",
      profession: "Bengaluru",
    },
    {
      description:
        "I have known Mr. Jayakumar for about a decade now when I first interacted with him while applying for my loan in 2013 with Standard Chartered Bank. He was the third banker to handle my loan application and I must tell you the overall experience was completely pleasing, and entire execution was flawless from day one until the registration. His maturity level and professionalism to handle client is exceptional, even though I had other options, but his convincing approach made me stick around with him without any hesitations. In fact, he became one of my first go to person for any capital need and very recently in 2021 once again he helped me get another loan with a lightning speed when he had his own company. All of that without me running around for anything. With gratitude, I would recommend him unequivocally for his trust and maturity in handling clients. It’s an absolute treat to work with him",
      img: "0",
      name: "Jitendra Kumar",
      profession: "Bengaluru",
    },
    {
      description:
        "After Covid turbulence, Jayakumar helped a lot to rearrange my Finances and reduced a lot of interest outflow. He always says, “Every rupee saved on Finance Cost (Bank Interest) is profit to the company”. I strongly recommend Jayakumar for his skills in understanding Businesses.",
      img: "0",
      name: "Rajendran",
      profession: "Director, M/s. Mekala Metal Works Pvt Ltd, Bengaluru",
    },
    {
      description:
        "Any loans / Properties - the only go to man for me is Jayakumar very sharp, intelligent and a very good person. Anyone dealing with him have to include him as a family member, such is his approach towards clients - All the best",
      img: "0",
      name: "Kuppuraj",
      profession: "Bengaluru",
    },
    {
      description:
        "I had known Jayakumar for more than a decade now - Apart from facilitating my home loan, he helped me in acquiring two commercial properties which has helped a lot in terms of high returns. His acumen on Loans and Real Estate is exemplary and apart from being a Financial Advisor, Jayakumar is more of a family to us",
      img: "0",
      name: "Basavaraju Siddappa",
      profession: "Bengaluru",
    },
  ];
  // Top and bottom scroll trigger animation
  const containerEl = useRef<HTMLDivElement>(null);
  const topEl = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: containerEl.current,
        start: "top center",
      },
    });
    tl.from(topEl.current, {
      opacity: 0,
      y: "100%",
    }).from(".testimonial-slick-list", {
      opacity: 0,
      y: "100%",
    });
    return () => {
      tl.scrollTrigger?.kill();
    };
  }, []);
  return (
    <Container ref={containerEl}>
      <ContainerTop ref={topEl}>
        <ParagraphWithLightBorder>Testimonial</ParagraphWithLightBorder>
        <TitleWithBigMargin>What Our Clients Say!</TitleWithBigMargin>
      </ContainerTop>
      <StyledSlider className="testimonial-slick-list" {...settings}>
        {slideItemArray.map(({ description, img, name, profession }, idx) => (
          <SlideItemContainer key={idx}>
            <SlideItem>
              <Top>
                <IconContainer>
                  <Icon icon={["fas", "quote-right"]} />
                </IconContainer>
                <StyledParagraph>{description}</StyledParagraph>
              </Top>
              <Image src={`${imgbaseUrl}testimonial-${img}.jpg`} />
              <StyledTitleH4>{name}</StyledTitleH4>
              <StyledParagraph>{profession}</StyledParagraph>
            </SlideItem>
          </SlideItemContainer>
        ))}
      </StyledSlider>
    </Container>
  );
};

export default Testimonial;
