export const commonData = {
    name: "Influx Capital",
    faceBooklink: "https://www.facebook.com/profile.php?id=100087954540350",
    linkedinLink: "https://www.linkedin.com/in/influx-capital-a6875b256/",
    twitterLink: "https://x.com/influx_capital",
    instaLink: "https://www.instagram.com/influxcapital/",
    youtubeLink: "https://www.youtube.com/channel/UCFgwsdEXI5MlkT2Pwo6kc4Q",
    email: "contact@influxcapital.in",
    phone: "+91 9845 212 313",
    address: "Brigade Meadows, Kanakapura Road, Bengaluru",
    addressMap: "https://maps.app.goo.gl/a5cY8KxuudT5q2zEA"
}