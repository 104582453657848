import React from "react";
import styled from "styled-components";
import { smDown } from "../utils/responsive";
import { WrapperContainer } from "./SliderItem";
const Container = styled.div`
  background-color: #000b1c;
`;
const ContainerWrapper = styled(WrapperContainer)`
  padding: 24px 12px;
`;
const Wrapper = styled.div`
  margin: 0 -12px;
  display: flex;
  justify-content: center;
  text-align: center;
  ${smDown({
    flexDirection: "column",
    alignItems: "center",
  })}
`;
const Col = styled.div`
  font-size: 1rem;
  font-family: "Open Sans", "sans-serif";
  font-weight: 400;
  line-height: 1.5;
  padding: 0 12px;
  color: ${({ theme }) => theme.palette.primary.light};
`;
const Copyright = () => {
  return (
    <Container>
      <ContainerWrapper>
        <Wrapper>
          <Col>
          Copyright © <>{(new Date().getFullYear())}</> Influx Capital, All rights reserved.
          </Col>
        </Wrapper>
      </ContainerWrapper>
    </Container>
  );
};

export default Copyright;
