import React, { useLayoutEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { WrapperContainer } from "./SliderItem";
import { Paragraph } from "./About";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName, IconProp, library } from "@fortawesome/fontawesome-svg-core";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { DefaultMediaIconContainer } from "./Header";
import {
  faFacebookF,
  faLinkedinIn,
  faXTwitter,
  faYoutube,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { mdDown, smDown, lgDown } from "../utils/responsive";
import { commonData } from "../data/common";
import { gsap } from "gsap";
library.add(faLocationDot, faPhone, faEnvelope);
const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  margin-top: 48px;
  padding: 48px 0;
`;
const Wrapper = styled(WrapperContainer)`
  padding: 48px 12px;
  ${mdDown({
  padding: "0 12px",
})}
`;
const ColWrapper = styled.div`
  margin: 0 -24px;
  display: flex;
  flex-wrap: wrap;
  ${smDown({
  margin: "0 -12px",
})}
`;
const Col = styled.div`
  width: 25%;
  padding: 0 24px;
  ${mdDown({
  width: "50%",
  marginTop: 48,
})}
  ${smDown({
  width: "100%",
  padding: "0 12px",
})}
`;
const FooterTitle = styled.h4`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 1.5rem;
  margin-bottom: 24px;
`;
const FooterSubTitle = styled.p`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 1rem;
`;
const OfficeRow = styled(Paragraph)`
  color: ${({ theme }) => theme.palette.primary.light};
  margin-bottom: 8px;
`;
const OfficeRowIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.primary.light};
  margin-right: 16px;
`;
const MediaContainer = styled.div`
  display: flex;
  margin-top: 8px;
`;
const MediaIconContainer = styled(DefaultMediaIconContainer)`
  background-color: transparent;
  height: 38px;
  width: 38px;
  margin-right: 8px;
  border: 1px solid ${({ theme }) => theme.palette.primary.light};
  transition: all 500ms ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
    & svg {
      color: #000;
    }
  }
`;
const MediaIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.primary.light};
`;
const LinkContainer = styled.div``;
const FooterLink = styled(Link)`
  display: block;
  color: ${({ theme }) => theme.palette.primary.light};
  margin-bottom: 5px;
  transition: all 300ms ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
    letter-spacing: 1px;
    box-shadow: none;
  }
`;
const FooterLinkIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;
const Image = styled.img`
  width: 250px;
  object-fit: cover;
  ${lgDown({
  width: "250px"
})}
`;
export const imglogoUrl = `${process.env.PUBLIC_URL}/assets/logo.png`;
const Footer = () => {
  interface IOfficeItem {
    iconName: IconName;
    title: string;
    to: string;
    target: string;
  }
  const officeItems: IOfficeItem[] = useMemo(
    () => [
      {
        iconName: "phone",
        title: commonData.phone,
        to: "tel:" + commonData.phone,
        target: ""
      },
      {
        iconName: "envelope",
        title: commonData.email,
        to: "mailto:" + commonData.email,
        target: ""
      },
      {
        iconName: "location-dot",
        title: commonData.address,
        to: commonData.addressMap,
        target: "_blank"
      }
    ],
    []
  );
  interface quickLinkType {
    name: string;
    to: string;
  }
  const serviceItem: quickLinkType[] = useMemo(
    () => [
      {
        name: "Retail Loans",
        to: "/retailloan",
      },
      {
        name: "SME Loans",
        to: "/smeloan",
      },
      {
        name: "Real Estate",
        to: "/realestate",
      },
      {
        name: "Project finance",
        to: "/projectfinance",
      },
      {
        name: "Mergers & Acquisitions",
        to: "/mergers",
      },
    ],
    []
  );

  const quickLinkItem: quickLinkType[] = useMemo(
    () => [
      {
        name: "Home",
        to: "/",
      },
      {
        name: "About Us",
        to: "/about",
      },
      {
        name: "Contact Us",
        to: "/contact",
      },
      {
        name: "Our Services",
        to: "/service",
      }
    ],
    []
  );
  // container scroll trigger animation
  const containerEl = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const footerTween = gsap.from(containerEl.current, {
      opacity: 0,
      scrollTrigger: {
        trigger: containerEl.current,
        start: "top-=50% center",
      },
    });

    return () => {
      footerTween?.kill();
    };
  }, []);
  return (
    <Container ref={containerEl}>
      <Wrapper>
        <ColWrapper>
        <Col>
            <Image src={imglogoUrl}></Image>
            <FooterSubTitle>Believing us is believing in your dreams. Unblock the financial blockage, and find the perfect solution for your venture.</FooterSubTitle>
          </Col>
          <Col>
            <FooterTitle>Services</FooterTitle>
            <LinkContainer>
              {serviceItem.map(({ name, to }, idx) => (
                <FooterLink key={idx} to={to}>
                  <FooterLinkIcon icon={["fas", "chevron-right"]} />
                  {name}
                </FooterLink>
              ))}
            </LinkContainer>
          </Col>
          <Col>
            <FooterTitle>Quick Links</FooterTitle>
            <LinkContainer>
              {quickLinkItem.map(({ name, to }, idx) => (
                <FooterLink key={idx} to={to}>
                  <FooterLinkIcon icon={["fas", "chevron-right"]} />
                  {name}
                </FooterLink>
              ))}
            </LinkContainer>
          </Col>
          <Col>
            <FooterTitle>Contact</FooterTitle>
            {officeItems.map(({ title, iconName, to, target }, idx) => (
              <OfficeRow key={idx}>
                <a href={to} target={target}>
                  <OfficeRowIcon icon={["fas", iconName]} /></a>
                {title}
              </OfficeRow>
            ))}
            <MediaContainer>
              <MediaIconContainer href={commonData.twitterLink} target='_blank'>
                <MediaIcon icon={faXTwitter as IconProp} />
              </MediaIconContainer>
              <MediaIconContainer href={commonData.faceBooklink} target='_blank'>
                <MediaIcon icon={faFacebookF as IconProp} />
              </MediaIconContainer>
              <MediaIconContainer href={commonData.youtubeLink} target='_blank'>
                <MediaIcon icon={faYoutube as IconProp} />
              </MediaIconContainer>
              <MediaIconContainer href={commonData.linkedinLink} target='_blank'>
                <MediaIcon icon={faLinkedinIn as IconProp} />
              </MediaIconContainer>
              <MediaIconContainer href={commonData.instaLink} target='_blank'>
                <MediaIcon icon={faInstagram as IconProp} />
              </MediaIconContainer>
            </MediaContainer>
          </Col>
        </ColWrapper>
      </Wrapper>
    </Container>
  );
};

export default Footer;
