import React from "react";
import { Helmet } from "react-helmet-async";
import ContactComponent from "../components/Contact";
import PageHeader from "../components/PageHeader";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Influx Capital - Contact</title>
        <meta name="description" content="Looking for financial solution companies that can give you peace of mind" />
        <link rel="canonical" href="/contact" />
      </Helmet>
      <PageHeader title="Contact" subPage=""/>
      <ContactComponent />
    </>
  );
};

export default Contact;
