import React from "react";
import { Helmet } from "react-helmet-async";
import Carousel from "../components/Carousel";
import About from "../components/About";
import Features from "../components/Features";
import Services from "../components/Services";
import Partners from "../components/Partners";
import TestimonialHome from "../components/TestimonialHome";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Influx Capital - Unblock the financial blockage</title>
        <meta name="description" content="Team of financial experts who are ready on their toes to assist you with the perfect solution. all the answers related to your loan query and consultation." />
        <link rel="canonical" href="/" />
      </Helmet>
      <Carousel />
      <About pageType="home"/>
      <Features />
      <Services />
      <Partners />
      <TestimonialHome />
    </>
  );
};

export default Home;
