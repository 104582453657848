import { Helmet } from "react-helmet-async";
import AboutComponent from "../components/About";
import PageHeader from "../components/PageHeader";
import React from "react";

const About = () => {
  return (
    <>
      <Helmet>
        <title>Influx Capital - About</title>
        <meta name="description" content="We are a team of financial experts who are ready on their toes to assist you with the perfect solution" />
        <link rel="canonical" href="/about" />
      </Helmet>
      <PageHeader title="About" subPage="" />
      <AboutComponent pageType="about" />
    </>
  );
};

export default About;
