import React from "react";
import { Helmet } from "react-helmet-async";
import PageHeader from "../components/PageHeader";
import ServiceDetail from "../components/ServiceDetail";
import { projectFinance } from "../data/serviceDetail";

const ProjectFinance = () => {
  return (
    <>
      <Helmet>
        <title>Influx Capital | Services - Project Finance</title>
        <meta name="description" content="Long-term vision of Influx Capital addressing critical needs and unlocking new opportunities" />
        <link rel="canonical" href="/projectfinance" />
      </Helmet>
      <PageHeader title="Project Finance" subPage="Services"/>
      <ServiceDetail service={projectFinance}/>
    </>
  );
};

export default ProjectFinance;
