import React from "react";
import { Helmet } from "react-helmet-async";
import PageHeader from "../components/PageHeader";
import ServicesComponent from "../components/Services";
import CallBack from "../components/CallBack";

const Service = () => {
  return (
    <>
      <Helmet>
        <title>Influx Capital - Services</title>
        <meta name="description" content="How we help your business check out our sevices" />
        <link rel="canonical" href="/service" />
      </Helmet>
      <PageHeader title="Services" subPage=""/>
      <ServicesComponent />
      <CallBack />
    </>
  );
};

export default Service;
