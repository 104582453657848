import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import { ButtonLink, WrapperContainer } from "./SliderItem";
import {
  Desc,
  Icon,
  ParagraphWithLightBorder,
  Paragraph,
  Title,
} from "./About";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import { mdDown, smDown } from "../utils/responsive";
import { gsap } from "gsap";
library.add(faArrowRight, faCheck);
const Container = styled(WrapperContainer)`
  padding: 48px 12px;
  display: flex;
  align-items: center;
  ${mdDown({
    flexDirection: "column",
  })}
`;
const Col = styled.div`
  width: 50%;
  padding: 0 24px;
  margin-top: 48px;
  ${mdDown({
    width: "100%",
  })}
`;
const Left = styled(Col)`
  padding-left: 0;
`;
const Right = styled(Col)`
  ${mdDown({
    paddingLeft: 0,
  })}
  ${smDown({
    flexDirection: "column",
  })}
`;
const RightCol = styled.div`
  margin: -24px -12px 0;
  display: flex;
  justify-content: center;
  ${smDown({
    flexDirection: "column",
  })}
`;

const RightItemContainer = styled.div`
  margin-top: 24px;
  padding: 0 12px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${smDown({
    width: "100%",
  })}
`;
const RightItem = styled.div`
  margin-top: 24px;
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.palette.primary.light};
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.palette.common.white};
  transition: all 500ms;
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    & * {
      color: ${({ theme }) => theme.palette.common.white};
    }
  }
`;
const RightItemTitle = styled.h4`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 1.5rem;
  margin: 16px 0;
  transition: all 500ms;
`;
const StyledParagraph = styled(Paragraph)`
  margin-bottom: 16px;
`;
const Features = () => {
  interface IRightItem {
    title: string;
    desc: string;
  }
  const rightItemLeftCol: IRightItem[] = [
    {
      title: "Easy Criteria",
      desc: "We have the minor paperwork involved, and the complete criteria are free of complexity. We help you to get the loan in the easiest way possible.",
    },
    {
      title: "Experience",
      desc: "We have an experienced team of financial advisors who exactly can help with the best-suited solutions. We have experience of several years, and we can directly advise you the best.",
    },
  ];
  const rightItemRightCol: IRightItem[] = [
    {
      title: "Reliability",
      desc: "We are one of the most trusted brands in terms of financial solution help. We have several smiling faces with a solution from our end.",
    },
    {
      title: "Right on time",
      desc: "We believe that your time is precious. So, we try to complete the process within the least time possible.",
    },
  ];
  // Left scroll trigger animation
  const leftEl = useRef<HTMLDivElement>(null);
  const expertsRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const tween = gsap.from(leftEl.current, {
      opacity: 0,
      y: "100%",
      duration: 0.5,
      scrollTrigger: {
        trigger: leftEl.current,
        start: "top-=200% center",
      },
    });

    return () => {
      tween.scrollTrigger?.kill();
    };
  }, []);
  // rightItems scroll trigger animation
  const rightEl = useRef<HTMLDivElement>(null);
  const rightItemsEl = useRef<HTMLDivElement[]>([]);
  const addToRightItemsEl = (el: HTMLDivElement) => {
    if (el && !rightItemsEl.current.includes(el)) rightItemsEl.current.push(el);
  };
  useLayoutEffect(() => {
    const tween = gsap.from(rightItemsEl.current, {
      opacity: 0,
      duration: 0.6,
      stagger: 0.2,
      scrollTrigger: {
        trigger: rightEl.current,
        start: "top-=15% center",
      },
    });

    return () => {
      tween.scrollTrigger?.kill();
    };
  }, []);
  return (
    <Container id="Experts" ref={expertsRef}>
      <Left ref={leftEl}>
        <ParagraphWithLightBorder>Experts</ParagraphWithLightBorder>
        <Title>Grow your business with our financial solutions!</Title>
        <Desc>
        A team of experts are well aware of the terms and norms of loans. We help you get fundings that are easy to repay, and we offer you consultancy as well in case of doubts. Our team supports you with the best financial advice and easy funding in your pocket and gives you a stand to rise. One of the most trusted and reliable teams of experts to take your business sky-high.
        </Desc>
        <ButtonLink to="/about">Explore More</ButtonLink>
      </Left>
      <Right>
        <RightCol ref={rightEl}>
          <RightItemContainer ref={addToRightItemsEl}>
            {rightItemLeftCol.map(({ title, desc }, idx) => (
              <RightItem key={idx}>
                <Icon icon={["fas", "check"]} />
                <RightItemTitle>{title}</RightItemTitle>
                <StyledParagraph>{desc}</StyledParagraph>
              </RightItem>
            ))}
          </RightItemContainer>
          <RightItemContainer ref={addToRightItemsEl}>
            {rightItemRightCol.map(({ title, desc }, idx) => (
              <RightItem key={idx}>
                <Icon icon={["fas", "check"]} />
                <RightItemTitle>{title}</RightItemTitle>
                <StyledParagraph>{desc}</StyledParagraph>
              </RightItem>
            ))}
          </RightItemContainer>
        </RightCol>
      </Right>
    </Container>
  );
};

export default Features;
