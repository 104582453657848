import { IconProp, library } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebookF,
  faLinkedinIn,
  faXTwitter
} from "@fortawesome/free-brands-svg-icons";
import {
  faArrowUp,
  faBars,
  faChevronDown,
  faClock,
  faEnvelope,
  faLocationDot,
  faPhone,
  faS,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import styled from "styled-components";
import { defaultTheme } from "../utils/theme"
import { lgDown, mdDown, mdUp } from "../utils/responsive";
import { gsap } from "gsap";
import { commonData } from "../data/common";
library.add(
  faLocationDot,
  faS,
  faClock,
  faEnvelope,
  faPhone,
  faChevronDown,
  faBars,
  faArrowUp
);

interface ContainerProps {
  isSticky: boolean;
  isDesktop: boolean;
}
const Container = styled.div<ContainerProps>`
  opacity: 0;
  padding: 0 48px;
  position: fixed;
  top: 0;
  transform: translateY(
    ${({ isSticky, isDesktop }) => (isSticky && isDesktop ? -45 : 0)}px
  );
  left: 0;
  right: 0;
  z-index: 4;
  transition: all 100ms ease;
  background-color: ${({ isSticky, theme }) =>
    isSticky ? theme.palette.common.white : "transparent"};
  box-shadow: ${({ isSticky, theme }) => (isSticky ? theme.boxShadow : "none")};
  ${mdDown({
      padding: "0 12px",
    })}
`;

const Top = styled.div`
  width: 100%;
  height: 45px;
  border-bottom: 1px solid rgba(53, 94, 252, 0.07);
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${mdDown({
  display: "none",
})}
`;
const Column = styled.div`
  & > small:last-child {
    margin-left: 24px;
  }
`;
const Small = styled.small`
  color: ${({ theme }) => theme.palette.common.black};
  font-size: 14px;
  font-family: "Open Sans", "sans-serif";
`;
const StyledIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  margin-right: 8px;
`;
const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 8px 0;
`;
const LogoContainer = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
`;
const Image = styled.img`
  width: 300px;
  object-fit: cover;
  ${lgDown({
  width: "230px"
})}
`;
interface RightProps {
  toggle: boolean;
}
const Right = styled.div<RightProps>`
  display: flex;
  transition: max-height 350ms ease;
  ${({ toggle, theme }) =>
    mdDown({
      display: "flex",
      maxHeight: toggle ? 447 : 0,
      position: "absolute",
      top: "100%",
      left: 0,
      right: 0,
      overflow: "hidden",
      backgroundColor: theme.palette.common.white,
      borderTop: toggle ? "1px solid rgba(0,0,0,0.07)" : "none",
    })}
`;
const Nav = styled.nav`
  display: flex;
  align-items: center;
  ${({ theme }) =>
    mdDown({
      flexDirection: "column",
      width: "100%",
      padding: `calc(${theme.containerPaddingX}px /2)`,
    })}
`;
// interface INavItem {
//   isactive: boolean;
// }
const NavItem = styled(NavLink)`
  text-decoration: none;
  &.active {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  color: ${({ theme }) => theme.palette.common.black};
  font-weight: 500;
  padding: 25px 15px;
  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  ${mdDown({
  width: "100%",
  padding: "10px 0",
})}
`;
const NavItemIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
  font-size: 10px;
  vertical-align: inherit;
`;
interface DropDownContainerProps {
  isactive: string;
  toggle: boolean;
}
const DropDownContainer = styled.span<DropDownContainerProps>`
  color: ${({ theme, isactive }) =>
    isactive === "true"
      ? theme.palette.primary.main
      : theme.palette.common.black};
  font-weight: 500;
  padding: 25px 15px;
  font-size: 1rem;
  font-family: "Open Sans", "sans-serif";
  position: relative;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
    & > svg {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
  ${mdUp({
        ["&:hover"]: {
          ["& > div"]: {
            opacity: 1,
            transform: "rotateX(0)",
          },
        },
      })}
  ${({ toggle }) =>
    mdDown({
      width: "100%",
      padding: "10px 0",
      ["& > div:last-of-type"]: {
        display: toggle ? "block" : "none",
      },
    })}
`;
const NavDropDown = styled.div`
  opacity: 0;
  position: absolute;
  top: 72px;
  left: 0;
  min-width: 160px;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 8px 0;
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid ${({ theme }) => theme.borderColor};
  transition: all 500ms ease;
  transform-origin: 0% 0%;
  transform: rotateX(-75deg);
  ${mdDown({
  position: "initial",
  transform: "rotateX(0deg)",
  marginTop: 10,
  opacity: 1,
})}
`;
const NavDropDownItem = styled(NavLink)`
  width: 100%;
  display: block;
  &.active {
    color: ${({ theme }) => theme.palette.common.white};
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
  color: #212529;
  padding: 4px 16px;
  font-weight: 400;
  line-height: 1.5;
  background-color: transparent;
  &:hover {
    &.active {
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
    background-color: #e9ecef;
  }
`;
const MediaContainer = styled.div`
  display: flex;
  align-items: center;
  ${mdDown({
  display: "none",
})}
`;
export const DefaultMediaIconContainer = styled.a`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const MediaIconContainer = styled(DefaultMediaIconContainer)`
  background-color: ${({ theme }) => theme.palette.primary.light};
  margin-left: 16px;
  height: 32px;
  width: 32px;
`;
export const MediaIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
`;
const BarsContainer = styled.button`
  display: none;
  padding: 4px 12px;
  background-color: transparent;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.55);
  border-radius: ${({ theme }) => theme.borderRadius};
  cursor: pointer;
  transition: box-shadow 150ms ease-in-out;
  outline: 0;
  &:focus {
    box-shadow: 0 0 0 0.25rem;
  }
  ${mdDown({
  display: "initial",
})}
`;
const ContactContainer = styled.div`
  display: none;
  ${mdDown({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  paddingBottom: "10px"
})}
`;
const ContactDiv = styled.small`
  color: ${({ theme }) => theme.palette.common.black};
  font-size: 14px;
  font-family: "Open Sans", "sans-serif";
`;
const Bars = styled(FontAwesomeIcon)`
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.55);
`;
export const hrefBaseUrl = (path: string) => `/${path}`;
export const imglogoUrl = `${process.env.PUBLIC_URL}/assets/logo.png`;
export const imglogosmallUrl = `${process.env.PUBLIC_URL}/assets/logosmall.png`;
const Header = () => {
  const { pathname } = useLocation();
  const [isPagesActive, setIsPagesActive] = useState(false);
  const [navToggle, setNavToggle] = useState(false);
  const [dropDownToggle, setDropDownToggle] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth > 992) setIsDesktop(true);
      else setIsDesktop(false);
      if (window.scrollY > 45) {
        setIsSticky(true);
      } else setIsSticky(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // Header Dropdown isActive setter
  useEffect(() => {
    if (
      ["/retailloan", "/smeloan", "/realestate", "/projectfinance", "/mergers"].includes(
        pathname
      )
    )
      setIsPagesActive(true);
    else setIsPagesActive(false);
  }, [pathname]);
  const handleToggleNav = () => {
    setNavToggle(!navToggle);
    setDropDownToggle(false);
  };

  const handleToggleNavClick = (e: unknown) => {
    if (e === 'mainMenu') {
      return;
    }
    setNavToggle(!navToggle);
    setDropDownToggle(false);
  };

  const handleToggleDropDown = () => {
    setDropDownToggle(!dropDownToggle);
  };

  // gsap fade-in on header bottom part
  const containerEl = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const headerTween = gsap.to(containerEl.current, {
      opacity: 1,
      duration: 1,
      scrollTrigger: {
        trigger: containerEl.current,
      },
    });

    return () => {
      headerTween.scrollTrigger?.kill();
    };
  }, []);
  return (
    <>
      <Container ref={containerEl} isSticky={isSticky} isDesktop={isDesktop}>
        <Top>
          <Column>
            <Small>
              <a href={commonData.addressMap} target="_blank">
                <StyledIcon icon={["fas", "location-dot"]} /></a>
              {commonData.address}
            </Small>
          </Column>
          <Column>
            <Small>
              <a href="mailto:contact@influxcapital.in">
                <StyledIcon icon={["fas", "envelope"]} /></a>
              {commonData.email}
            </Small>
            <Small>
              <a href="tel:+919845212313">
                <StyledIcon icon={"phone"} /></a>
              {commonData.phone}
            </Small>
          </Column>
        </Top>
        <Bottom>
          {/* <ContactContainer>
            <ContactDiv>
              <a href="mailto:contact@influxcapital.in">
                <StyledIcon icon={["fas", "envelope"]} /></a>
              {commonData.email}</ContactDiv>
            <ContactDiv>
              <a href="tel:+919845212313">
                <StyledIcon icon={"phone"} /></a>
              {commonData.phone}</ContactDiv>
          </ContactContainer> */}
          <LogoContainer to="/">
            <Image src={imglogoUrl}></Image>
          </LogoContainer>
          <Right toggle={navToggle}>
            <Nav onClick={(event: any) => handleToggleNavClick(event.target.id)}>
              <NavItem to="">Home</NavItem>
              <NavItem to="/about">About</NavItem>
              <DropDownContainer id="mainMenu"
                onClick={handleToggleDropDown}
                toggle={dropDownToggle}
                isactive={`{isPagesActive}`}
              >
                Services
                <NavItemIcon icon={["fas", "chevron-down"]} id="mainMenuIcon" />
                <NavDropDown>
                  <NavDropDownItem to="/retailloan">Retail Loans</NavDropDownItem>
                  <NavDropDownItem to="/smeloan">SME Loans</NavDropDownItem>
                  <NavDropDownItem to="/realestate">Real Estate</NavDropDownItem>
                  <NavDropDownItem to="/projectfinance">Project Finance</NavDropDownItem>
                  <NavDropDownItem to="/mergers">Mergers & Acquisitions</NavDropDownItem>
                </NavDropDown>
              </DropDownContainer>
              <NavItem to="/testimonial">Testimonial</NavItem>
              <NavItem to="/contact">Contact</NavItem>
            </Nav>
            <MediaContainer>
              <MediaIconContainer href={commonData.faceBooklink} target='_blank'>
                <MediaIcon icon={faFacebookF as IconProp} />
              </MediaIconContainer>
              <MediaIconContainer href={commonData.twitterLink} target='_blank'>
                <MediaIcon icon={faXTwitter as IconProp} />
              </MediaIconContainer>
              <MediaIconContainer href={commonData.linkedinLink} target='_blank'>
                <MediaIcon icon={faLinkedinIn as IconProp} />
              </MediaIconContainer>
            </MediaContainer>
          </Right>
          <BarsContainer onClick={handleToggleNav}>
            <Bars icon={["fas", "bars"]} />
          </BarsContainer>
        </Bottom>
      </Container>
      <ScrollToTop smooth style={{ bottom: '80px', right: '30px', background: defaultTheme.palette.primary.main, borderRadius: "50%" }} color={defaultTheme.palette.common.white}
        svgPath="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2 160 448c0 17.7 14.3 32 32 32s32-14.3 32-32l0-306.7L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
        width="15" height="15" viewBox="0 0 380 380">
      </ScrollToTop>
    </>
  );
};

export default Header;