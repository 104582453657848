import React from "react";
import { Helmet } from "react-helmet-async";
import PageHeader from "../components/PageHeader";
import ServiceDetail from "../components/ServiceDetail";
import { retailLoan } from "../data/serviceDetail";

const RetailLoan = () => {
  return (
    <>
      <Helmet>
        <title>Influx Capital | Services - Retail Loans</title>
        <meta name="description" content="Are you looking to make a significant purchase, consolidate debt, or cover unexpected expenses? Influx Capital offers a range of retail loan solutions tailored to meet your financial needs and help you achieve your goals" />
        <link rel="canonical" href="/retailloan" />
      </Helmet>
      <PageHeader title="Retail Loans" subPage="Services"/>
      <ServiceDetail service={retailLoan}/>
    </>
  );
};

export default RetailLoan;
