import { IconName, library } from "@fortawesome/fontawesome-svg-core";
import {
  faCircleXmark,
  faPhoneFlip,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gsap } from "gsap";
import React, { useLayoutEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { lgDown, mdDown, smDown, xlDown, xsDown } from "../utils/responsive";
import { aboutData } from "../data/about";
import {
  ParagraphWithBorder,
  WrapperContainer,
  imgbaseUrl,
} from "./SliderItem";
library.add(faCircleXmark, faUsers, faPhoneFlip);
const Container = styled(WrapperContainer)`
  padding: 48px 12px;
`;
const ColWrapper = styled.div`
  display: flex;
  margin: 0 -12px 24px;
  ${mdDown({
  flexDirection: "column",
})};
`;
const Col = styled.div`
  padding: 0 12px;
  height: 624px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  ${xlDown({
  height: 421,
})};
  ${lgDown({
  height: 586,
})};
  ${mdDown({
  width: "100%",
})};
`;
const Left = styled(Col)`
  opacity: 0;
  padding-bottom: 20px;
  transform: translateY(100%);
  ${mdDown({
  height: 696,
})};
  ${smDown({
  height: 516,
})};
  ${xsDown({
  height: "auto",
})};
`;
const Right = styled(Col)`
  height: auto;
  opacity: 0;
  transform: translateY(100%);
`;
export const ParagraphWithLightBorder = styled(ParagraphWithBorder)`
  border-color: ${({ theme }) => theme.palette.primary.light};
`;
const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: ${({ theme }) => theme.borderRadius};
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 3rem;
  margin-bottom: 24px;
  ${lgDown({
  fontSize: "calc(1.425rem + 2.1vw)",
})}
`;
export const Desc = styled.p`
  color: ${({ theme }) => theme.palette.common.black};
  margin-bottom: 24px;
  font-weight: 400;
`;
const TabContainer = styled.div`
  width: 100%;
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.palette.primary.light};
  border-radius: ${({ theme }) => theme.borderRadius};
`;
const TabHeader = styled.div`
  margin-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.light};
`;
interface TabTitleProps {
  isSelected: boolean;
}
const TabTitle = styled.button<TabTitleProps>`
  color: ${({ isSelected, theme }) =>
    isSelected ? "#495057" : theme.palette.primary.main};
  padding: 8px 16px;
  background-color: transparent;
  border-color: ${({ theme }) => theme.palette.common.black};
  border-width: 0;
  transition: all 150ms ease-in-out;
  border-width: 1px;
  border-style: solid;
  border-radius: ${({ theme }) =>
    `${theme.borderRadius} ${theme.borderRadius} 0 0`};
  margin-bottom: -1px;
  border-color: ${({ isSelected }) =>
    isSelected ? "#dee2e6 #dee2e6 #fff" : "transparent"};
  cursor: pointer;
  font-size: 1rem;
  font-family: "Open Sans", "sans-serif";
  font-weight: 500;
  line-height: inherit;
  &:hover {
    border-color: ${({ isSelected }) =>
    isSelected ? "#dee2e6 #dee2e6 #fff" : "#dee2e6 #dee2e6 transparent"};
  }
`;
interface TabDescContainerProps {
  isSelected: boolean;
}
export const fadeout = keyframes`
  0% {
    display:none;
    opacity:0;
  }
  70% {
    display:none;
    opacity:0;
  }
  100% {
    display:block;
    opacity:1;
  }
`;
const TabDescContainer = styled.div<TabDescContainerProps>`
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
  display: ${({ isSelected }) => (isSelected ? "block" : "none")};
  animation: ${fadeout} 300ms linear;
`;
export const Paragraph = styled.p`
  color: ${({ theme }) => theme.palette.common.black};
`;
const StyledParagraph = styled(Paragraph)`
  &:first-of-type {
    margin-bottom: 16px;
  }
`;
const AboutFooter = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.primary.light};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 24px;
  display: flex;
  justify-content: space-evenly;
  ${mdDown({
  flexDirection: "column",
})}
`;
const AboutFooterItem = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 12px;
  display: flex;
`;
export const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 3rem;
`;
const AboutFooterDescContainer = styled.div`
  padding-left: 10px;
  display: flex;
  align-items: center;
  ${smDown({
  paddingLeft: 16,
})}
`;
export const TitleH4 = styled.h4`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 1.5rem;
  ${lgDown({
  fontSize: "calc(1rem + .3vw)",
})}
`;
interface PageAboutProps {
  pageType: string;
}
const About = ({ pageType }: PageAboutProps) => {
  const handleSelect = (idx: number) => {
    setTabIndex(idx);
  };

  const [tabIndex, setTabIndex] = useState(0);
  const tabTitles: string[] = ["Goal", "Mission", " Vision"];
  interface ITabDesc {
    firstDesc: string;
    secondDesc: string;
  }
  const tabDescs: ITabDesc[] = [
    {
      firstDesc:
        "To be the reason for trust and tranquility behind every businesses looking for financial acumen.",
      secondDesc:
        "",
    },
    {
      firstDesc:
        "We look to be the most reliable firm in terms of financial consultation and funding. We foresee ourselves as the synonym of every financial solution-based company in the region.",
      secondDesc:
        "",
    },
    {
      firstDesc:
        "To be one of the leading finance support companies for those on the urge to taste success.",
      secondDesc:
        "",
    },
  ];
  interface IFooterItem {
    iconName: IconName;
    title: string;
  }
  const footerItems: IFooterItem[] = [
    {
      iconName: "circle-xmark",
      title: "No Hidden Cost",
    },
    {
      iconName: "users",
      title: "Dedicated Team",
    },
    {
      iconName: "phone-flip",
      title: "24/7 Available",
    },
  ];
  // Left on scroll animation
  const leftEl = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const tween = gsap.to(leftEl.current, {
      opacity: 1,
      y: 0,
      delay: 0.1,
      duration: 0.1,
      scrollTrigger: {
        trigger: leftEl.current,
        start: "top-=170% center",
      },
    });
    return () => {
      tween.scrollTrigger?.kill();
    };
  }, []);
  // Right on scroll animation
  const rightEl = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const tween = gsap.to(rightEl.current, {
      opacity: 1,
      y: 0,
      delay: 0.3,
      duration: 0.3,
      scrollTrigger: {
        trigger: rightEl.current,
        start: "top-=150% center",
      },
    });
    return () => {
      tween.scrollTrigger?.kill();
    };
  }, []);
  // aboutFooter container and child animation on scroll
  const aboutFooterEl = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const t1 = gsap.timeline({
      scrollTrigger: {
        trigger: aboutFooterEl.current,
        start: "top-=400% center",
      },
    });
    t1.from(aboutFooterEl.current, {
      opacity: 0,
      y: "100%",
      delay: 0.1,
      duration: 0.2,
    });
    t1.from(revealRefs.current, {
      opacity: 0,
      y: "100%",
      duration: 0.5,
      stagger: 0.1,
    });
    return () => t1.scrollTrigger?.kill();
  }, []);
  // AboutFooterItem animation on scroll
  const revealRefs = useRef<HTMLDivElement[]>([]);
  const addToRefs = (el: HTMLDivElement) => {
    if (el && !revealRefs.current.includes(el)) revealRefs.current.push(el);
  };
  return (
    <Container>
      <ColWrapper>
        <Left ref={leftEl}>
          <Image src={`${imgbaseUrl}aboutus.jpg`} />
        </Left>
        <Right ref={rightEl}>
          <ParagraphWithLightBorder>About Us</ParagraphWithLightBorder>
          <Title>Who are We?</Title>
          {pageType === "home" && <Desc>
            {aboutData.descOne}
          </Desc>}
          {pageType === "about" && <><Desc>
            {aboutData.descTwo}
          </Desc><Desc>
              {aboutData.descThree}
            </Desc></>}
          {pageType === "home" && <TabContainer>
            <TabHeader>
              {tabTitles.map((title, idx) => (
                <TabTitle
                  key={idx}
                  onClick={() => handleSelect(idx)}
                  isSelected={tabIndex === idx}
                >
                  {title}
                </TabTitle>
              ))}
            </TabHeader>
            {tabDescs.map(({ firstDesc, secondDesc }, idx) => (
              <TabDescContainer key={idx} isSelected={tabIndex === idx}>
                <StyledParagraph>{firstDesc}</StyledParagraph>
                <StyledParagraph>{secondDesc}</StyledParagraph>
              </TabDescContainer>
            ))}
          </TabContainer>}
        </Right>
      </ColWrapper>
      <AboutFooter ref={aboutFooterEl}>
        {footerItems.map(({ iconName, title }, idx) => (
          <AboutFooterItem ref={addToRefs} key={idx}>
            <Icon icon={["fas", iconName]} />
            <AboutFooterDescContainer>
              <TitleH4>{title}</TitleH4>
            </AboutFooterDescContainer>
          </AboutFooterItem>
        ))}
      </AboutFooter>
    </Container>
  );
};

export default About;
