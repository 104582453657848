import React from "react";
import { Helmet } from "react-helmet-async";
import PageHeader from "../components/PageHeader";
import TestimonialComponent from "../components/Testimonial";

const Testimonial = () => {
  return (
    <>
      <Helmet>
        <title>Influx Capital - Testimonial</title>
        <meta name="description" content="What our clients say" />
        <link rel="canonical" href="/testimonial" />
      </Helmet>
      <PageHeader title="Testimonial" subPage=""/>
      <TestimonialComponent />
    </>
  );
};

export default Testimonial;
