import React from "react";
import { Helmet } from "react-helmet-async";
import PageHeader from "../components/PageHeader";
import NotFoundComponent from "../components/NotFound";
import { gsap } from "gsap";

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>Influx Capital - 404 Error</title>
      </Helmet>
      <PageHeader title="404 Error" subPage=""/>
      <NotFoundComponent />
    </>
  );
};

export default NotFound;
