export const retailLoan = {
    name: "Retail Loans",
    img: "service-1",
    descOne: "Individuals with good credit are eligible for retail loans. Banks and financial organizations want to ensure that such loans are repaid on time; thus, having a solid repayment history and credit score is critical when applying for a Retail loan. The financial institution's predetermined terms and conditions require interest to be paid monthly or annually With the help of Influx capital, you get the best deal on retail loans with the least interest. We will be suggested with the best pock friendly offers with the most reliable partners.",
    descTwo: "",
    subHead: true,
    subName: "Here are some of the other subcategories that we help you in getting funding",
    subList: [
        {
            name: "Personal Loan",
            desc: "Available with little paperwork mainly for Salaried Clients. Personal loan is a loan that does not require collateral or security and is available with little paperwork. With Influx Capital, we take care of your pocket. We help you with consultation and also manage your pre-running loan more straightforwardly."
        },
        {
            name: "Home Loan",
            desc: "The easiest way to own your dream home is to own your dream home. Influx Capital helps you to get the best funding partners and manage EMIs easily. We are the most trusted partners when it comes to loans and funding."
        },
        {
            name: "Loan Against Property",
            desc: "Struggling to arrange finance but have hard money like property? You can get a loan against your property. We will help you get the best property offer from our trusted partners. Pay the loan in easy EMIs with us."
        },
        {
            name: "Lease Rental Discounting",
            desc: "This tool will help you acquire loans from the bank by using your rental receipt as collateral. We provide you with a consultation to manage it and also provide you with the banks and funding partners who can make a profitable deal."
        },
        {
            name: "Business Installment Loan",
            desc: "This is an Unsecured Loan given to Self Employed Businesses to cater to their immediate fund requirements. Business Loans are generally given for a shorter duration of 12 - 60 months only. Influx Capital guides you to avail this facility on a time bound manner."
        }
    ]
}

export const smeLoan = {
    name: "Small Medium Enterprises Loan",
    img: "service-2",
    descOne: "As the name suggests, it is Small Medium Enterprise Loan. It provides you with Substantial financial Opportunities at an affordable cost. At Influx Capital, we help you get SME loans to propel your business growth. We help you by consulting and aiding you with the perfect solutions. We help you find the top-notch funding partner that can provide you with the least or easy EMI loans.",
    descTwo: "Influx Capital with their team of Experts will guide you with the best possible financial solutions to engineer your business growth. So, are you in a dilemma still? Then contact us for the near-perfect solutions for your Small and Medium Business.",
    subHead: true,
    subName: "We also assist with",
    subList: [
        {
            name: "Cash Credit",
            desc: "A quick loan for small businesses with liquidity crunches. This is a short-term working loan that is extended by financial institutions, allowing borrowers to utilize money without holding a balance."
        },
        {
            name: "Bank Overdraft",
            desc: "The name somehow gives an overview of what it is. It works as even if you don’t have credit for withdrawal and transaction, the bank allows you to do that. You can pay once your payment is clear."
        },
        {
            name: "Letter of Credit",
            desc: "A credit letter, or letter of credit, is a letter in which the bank takes the guarantee of the buyer’s total payment to the seller. If, in any case, the buyer fails, banks pay on their behalf, and later bank can retrieve it from the buyer."
        },
        {
            name: "Project loan",
            desc: "To fund your project bank provides you loan. You have to pay the loan in EMIs. But before that you have to convince the bank according to that. Influx Capital helps you explaining your thoughts on your behalf."
        },
        {
            name: "Term Loan",
            desc: "A loan that has specific amount and pre-scheduled terms but with floating interest. Our team helps you bag the best term deals that makes your loan repayment easy and smooth."
        }
    ]
}

export const realEstate = {
    name: "Real Estate",
    img: "service-3",
    descOne: "Property asset is no less than investing in goal. A home is a dream, and office is passion. To make your dreams and passion marry together, Influx Capital becomes the priests. Our consulting helps you with finding beautiful office, home or other commercial business.",
    descTwo: "We aid you with proper source in buying selling and renting property with security and valuable price. we are your right hand in managing your properties like home, farms many other such services are waiting for you.",
    subHead: true,
    subName: "",
    subList: [
        {
            name: "Commercial",
            desc: "Banks provide loans on commercial complex or office purchasing. You can consult Influx Capitals for top-notch real estate deals regarding the section. We can also suggest you the trustworthy plans fopr such investment."
        },
        {
            name: "Residential Loan",
            desc: "Manage your residential property loans with us. Dream of owning a home or a bungalow or a villa won’t be just a dream but a lot more than that with our connoisseur members. We are in the business for more than decade and our solution is our consistency."
        }
    ]
}

export const mergers = {
    name: "Merger and Acquisitions",
    img: "service-4",
    descOne: "It is a common term to describe the consolidation of companies or their assets through different types of finances. It involves mergers, acquisitions, consolidations, tender offers, purchase of assets and management acquisition, etc.",
    descTwo: "To understand it better, it is about overtaking or shaking hands with each other. But it can be complex while managing all the terms and conditions. Once Influx Capitals is into helping you, this becomes easy. These mergers and acquisitions occur under specific terms and conditions, considering the profit generation. Our expert and professional legal teams help you by consulting on the essentiality of the claws and terms and conditions in the merger and acquisition bond or contract.",
}

export const projectFinance = {
    name: "Project Finance",
    img: "service-5",
    descOne: "Project finance is a specialized area of finance that focuses on funding large-scale projects, typically involving significant capital investment and complex financial structures. It's widely used in sectors such as infrastructure, energy, real estate, and natural resources.",
    descTwo: "It is a method of funding where the project's cash flows, assets, and future revenue are used as the primary source of repayment for the loans or investments. Unlike traditional financing, project finance often involves non-recourse or limited recourse loans, meaning lenders or investors have limited claim to the project sponsor’s other assets.",
}