import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import { WrapperContainer, imgbaseUrl } from "./SliderItem";
import { Paragraph, ParagraphWithLightBorder, TitleH4 } from "./About";
import { TitleWithBigMargin } from "./Services";
import { ButtonLink } from "./SliderItem";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { gsap } from "gsap";
import { lgDown } from "../utils/responsive";
library.add(faQuoteRight);
const Container = styled(WrapperContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 48px;
`;
const ContainerTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledSlider = styled.div`
  display: flex;
  width: 100%;
  ${lgDown({
    flexDirection: "column"
  })}
`;
const SlideItemContainer = styled.div`
  display: flex;
  padding: 30px;
`;
const SlideItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Top = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.primary.light};
  padding: 48px 24px 24px;
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius};
  margin-bottom: 48px;
  ::after,
  ::before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    left: 50%;
    transform: translateX(-50%);
  }
  :after {
    top: 100%;
    border-color: ${({ theme }) => theme.palette.common.white} transparent
      transparent transparent;
    border-width: 30px;
  }
  :before {
    top: 100.11%;
    border-color: ${({ theme }) => theme.palette.primary.light} transparent
      transparent transparent;
    border-width: 32px;
  }
`;
const IconContainer = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.palette.primary.light};
  background-color: ${({ theme }) => theme.palette.common.white};
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
`;
const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 2rem;
`;
const IconMore = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.common.white};
  margin-left: 4px;
`;
const StyledParagraph = styled(Paragraph)`
  text-align: center;
`;
const StyledTitleH4 = styled(TitleH4)`
  text-align: center;
`;
const Image = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 16px;
  border-radius: 50%;
`;
const TestimonialHome = () => {
  interface SlideItemType {
    description: string;
    img: string;
    name: string;
    profession: string;
  }
  const slideItemArray: SlideItemType[] = [
    {
      description:
        "I have known Jayakumar for more than 15 years now and he has always been my first point of contact as a financial advisor. I highly appreciate his positive attitude, knowledge, skills, and commitment towards meeting his target goals. He is most certainly an industry expert in my niche and it’s always been a great pleasure working with him.",
      img: "1",
      name: "Dr Amjad Khan & Zamir Ali Khan",
      profession: "Project Manager / Health Information & Communication Technology Specialist - Hamad Medical Corporation, Qatar",
    },
    {
      description:
        "Jayakumar had played a crucial role in the development of my business. His acumen on understanding Businesses and their requirements are truly appreciable. In the last 12 years of my association with Jayakumar, I had never taken any financial decisions without his advice. I admire his work ethics and decision making capability. Having him by the side will help any businesses to grow",
      img: "2",
      name: "K R Mohan Kumar",
      profession: "Chairman & Managing Director M/s. Veeranarayana Metal Alloys Pvt Ltd, Bengaluru",
    }
  ];
  // Top and bottom scroll trigger animation
  const containerEl = useRef<HTMLDivElement>(null);
  const topEl = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: containerEl.current,
        start: "top center",
      },
    });
    tl.from(topEl.current, {
      opacity: 0,
      y: "100%",
    }).from(".child-testi-home", {
      opacity: 0,
      y: "100%",
    });
    return () => {
      tl.scrollTrigger?.kill();
    };
  }, []);
  return (
    <Container ref={containerEl}>
      <ContainerTop ref={topEl}>
        <ParagraphWithLightBorder>Testimonial</ParagraphWithLightBorder>
        <TitleWithBigMargin>Happy Clients Feedback</TitleWithBigMargin>
      </ContainerTop>
      <StyledSlider className="child-testi-home">
        {slideItemArray.map(({ description, img, name, profession }, idx) => (
          <SlideItemContainer key={idx}>
            <SlideItem>
              <Top>
                <IconContainer>
                  <Icon icon={["fas", "quote-right"]} />
                </IconContainer>
                <StyledParagraph>{description}</StyledParagraph>
              </Top>
              <Image src={`${imgbaseUrl}testimonial-${img}.jpg`} />
              <StyledTitleH4>{name}</StyledTitleH4>
              <StyledParagraph>{profession}</StyledParagraph>
            </SlideItem>
          </SlideItemContainer>
        ))}
      </StyledSlider>
      <ButtonLink className="child" to="/testimonial">
          More Feedback
          <IconMore icon={["fas", "arrow-right"]} />
      </ButtonLink>
    </Container>
  );
};

export default TestimonialHome;
