import styled from "styled-components";
import { ParagraphWithLightBorder } from "./About";
import { WrapperContainer, imgbaseUrl } from "./SliderItem";
import { TitleWithBigMargin } from "./Services";
import { mdDown, xsDown } from "../utils/responsive";
import { gsap } from "gsap";
import React, { ChangeEvent, useLayoutEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Comment } from 'react-loader-spinner';
const Container = styled.div`
  width: 100%;
  margin-top: 48px;
  padding: 48px 0;
  position: relative;
  ::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(
        rgba(53, 94, 252, 0.95),
        rgba(53, 94, 252, 0.95)
      ),
      url(${imgbaseUrl}bg.png);
    z-index: -1;
  }
`;
const StyledWrapperContainer = styled(WrapperContainer)`
  padding-top: 48px;
  display: flex;
  justify-content: center;
`;
const Wrapper = styled.div`
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.borderRadius};
  width: 58%;
  padding: 48px;
  margin: 0 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.primary.light};
  ${mdDown({
    width: "100%",
    margin: 0,
  })}
`;
const Bottom = styled.form`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
export const InputContainer = styled.div`
  margin-top: 16px;
  padding: 0 8px;
  width: 50%;
  ${xsDown({
    width: "100%",
  })}
`;
export const Input = styled.input`
  color: ${({ theme }) => theme.palette.common.black};
  margin-top: 16px;
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  padding: 16px 12px;
  border: 1px solid ${({ theme }) => theme.palette.primary.light};
  border-radius: ${({ theme }) => theme.borderRadius};
  line-height: 1.5;
  font-weight: 400;
  width: 100%;
  outline: 0;
  transition: all 150ms ease-in-out;
  &:focus {
    border-color: #9aaffe;
    box-shadow: 0 0 0 4px rgba(53, 94, 252, 0.25);
  }
`;

export const TextArea = styled.textarea`
  color: ${({ theme }) => theme.palette.common.black};
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.primary.light};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 16px;
  margin: 16px 8px 0;
  height: 100px;
  outline: 0;
  transition: all 150ms ease-in-out;
  resize: vertical;
  &:focus {
    border-color: #9aaffe;
    box-shadow: 0 0 0 4px rgba(53, 94, 252, 0.25);
  }
`;
export const Button = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.light};
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding: 16px 48px;
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  display: inline-block;
  transition: all 300ms ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.darker};
    border-color: ${({ theme }) => theme.palette.primary.darker};
  }
  &:focus {
    box-shadow: 0 0 0 4px rgba(83, 118, 252, 0.5);
    background-color: ${({ theme }) => theme.palette.primary.darker};
    border-color: ${({ theme }) => theme.palette.primary.darker};
  }
`;
const FormButton = styled(Button)`
  width: 100%;
  margin: 16px 8px 0;
  text-align: center;
`;
const CallBack = () => {
  // Wrapper scroll trigger animation
  const [nameVal, setNameVal] = useState({ value: '' });
  const [emailVal, setEmailVal] = useState({ value: '' });
  const [mobileVal, setMobileVal] = useState({ value: '' });
  const [subjectVal, setSubjectVal] = useState({ value: '' });
  const [messageVal, setMessageVal] = useState({ value: '' });
  const [sendLoad, setSendLoad] = useState(false);
  const handleChangeName = (event: ChangeEvent<{ value: string }>) => {
    setNameVal({ value: event?.currentTarget?.value });
  }
  const handleChangeEmail = (event: ChangeEvent<{ value: string }>) => {
    setEmailVal({ value: event?.currentTarget?.value });
  }
  const handleChangeMobile = (event: ChangeEvent<{ value: string }>) => {
    setMobileVal({ value: event?.currentTarget?.value });
  }
  const handleChangeSubject = (event: ChangeEvent<{ value: string }>) => {
    setSubjectVal({ value: event?.currentTarget?.value });
  }
  const handleChangeMessage = (event: ChangeEvent<{ value: string }>) => {
    setMessageVal({ value: event?.currentTarget?.value });
  }
  const onSubmit = async (event: any) => {
    event.preventDefault()
    if (!nameVal.value || !emailVal.value || !mobileVal.value ) {
      alert("Please atleast provide name, email and mobile");
      return;
    }
    setSendLoad(true)
    try {
      const templateParams = {
        name: nameVal.value,
        email: emailVal.value,
        mobile: mobileVal.value,
        subject: subjectVal.value,
        message: messageVal.value,
      };
      await emailjs.send(
        "service_l7dvock",
        "template_fjhrvxe",
        templateParams,
        "RF-Lcbk9v1sON25zz"
      );
      console.log("success")
      setSendLoad(false)
      reset();
    } catch (e) {
      console.log(e);
      setSendLoad(false)
    }
  };

  const reset = () => {
    setNameVal({ value: "" });
    setEmailVal({ value: "" });
    setSubjectVal({ value: "" });
    setMessageVal({ value: "" });
  };
  const wrapperEl = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const callbackTween = gsap.from(wrapperEl.current, {
      opacity: 0,
      y: "100%",
      scrollTrigger: {
        trigger: wrapperEl.current,
        start: "top-=120% center",
      },
    });
    return () => {
      if (callbackTween) callbackTween.kill();
    };
  }, []);
  return (
    <Container>
      <StyledWrapperContainer>
        <Wrapper ref={wrapperEl}>
          <ParagraphWithLightBorder>Get In Touch</ParagraphWithLightBorder>
          <TitleWithBigMargin>Request A Call-Back</TitleWithBigMargin>
          <Bottom>
            <InputContainer>
              <Input placeholder="Your Name" value={nameVal.value}  onChange={handleChangeName}/>
            </InputContainer>
            <InputContainer>
              <Input placeholder="Your Email" value={emailVal.value} onChange={handleChangeEmail}/>
            </InputContainer>
            <InputContainer>
              <Input placeholder="Your Mobile" value={mobileVal.value} onChange={handleChangeMobile}/>
            </InputContainer>
            <InputContainer>
              <Input placeholder="Subject" value={subjectVal.value} onChange={handleChangeSubject}/>
            </InputContainer>
            <TextArea placeholder="Message" value={messageVal.value} onChange={handleChangeMessage}/>
            <FormButton type="submit" onClick={onSubmit} disabled={sendLoad}>Submit Now</FormButton>
            {sendLoad && <Comment
              visible={true}
              height="80"
              width="80"
              ariaLabel="comment-loading"
              wrapperStyle={{}}
              wrapperClass="comment-wrapper"
              color="#fff"
              backgroundColor="#F4442E"
            />}
          </Bottom>
        </Wrapper>
      </StyledWrapperContainer>
    </Container>
  );
};

export default CallBack;
