import React from "react";
import RoutesWrapper from "./components/RoutesWrapper";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
function App() {
  return <RoutesWrapper />;
}

export default App;
