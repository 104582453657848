import React from "react";
import { Helmet } from "react-helmet-async";
import PageHeader from "../components/PageHeader";
import ServiceDetail from "../components/ServiceDetail";
import { mergers } from "../data/serviceDetail";

const Mergers = () => {
  return (
    <>
      <Helmet>
        <title>Influx Capital | Services - Mergers & Acquisitions</title>
        <meta name="description" content="Marks the beginning of a powerful partnership that will shape the future of your industry" />
        <link rel="canonical" href="/mergers" />
      </Helmet>
      <PageHeader title="Mergers & Acquisitions" subPage="Services"/>
      <ServiceDetail service={mergers}/>
    </>
  );
};

export default Mergers;
